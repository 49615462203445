.feature-box:hover{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transition: ease 1s;
}


.site-footer
{
  background-color:#26272b;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px
}
.site-footer a
{
  color:#737373;
}
.site-footer a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a
{
  color:#737373
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:#3366cc;
  text-decoration:none;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:#33353d
}
.copyright-text
{
  margin:0
}

.appmockup{
  width: 400px;
  height: auto;
}

.download-container{
  margin-top: 30%;
}


@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }


  .download-container{
    margin-top: 30%;
  }
  

}
@media (max-width:767px)
{

  
.download-container{
  margin-top: 50px;
}

  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
  background-color:#29aafe
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.linkedin:hover
{
  background-color:#007bb6
}
.social-icons a.dribbble:hover
{
  background-color:#ea4c89
}
@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}


#logoanimation{
  animation: rotation 1s infinite linear; 
  display:flex;
  align-items: center;
  flex-direction: column;
}

@keyframes rotation {
  100%{ transform:rotatey(360deg) }
}

.loan-box{
  padding:20px 20px 20px 20px;
  margin: 80px 0px 80px 0px;
  background-color: lightgrey;
  border-radius: 5px;
  
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



.container {
  width: 100%;
  height: auto;
}
.slider {
  height: 300px;
  width: max-content;
  margin: auto;
  display: flex;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
  color: white;
}
.slider .slide {
  width: 275px;
  height: 200px;
  background: gray;
  display: none;
  font-size: 50px;
  z-index: 2;
  border-radius: 2px;
  padding-top: 100px;
  box-shadow: 0px 15px 10px -10px lightgray;
}
.slider .slide:nth-child(1) {
  display: block;
}
.slider .slide:nth-child(even) {
  background: orange;
}
.slider .slide:nth-child(odd) {
  background-color: coral;
}
.indicators {
  width: max-content;
  height: 20px;
  margin: 20px auto 0px;
  display: flex;
}
.indicators .indicator {
  background: darkgray;
  height: 10px;
  width: 10px;
  margin: 2px;
  border-radius: 50%;
}
.indicators .indicator:nth-child(1) {
  background: dimgray;
}
.prev,
.next {
  position: absolute;
  font-size: 20px;
  border: none;
  padding: 15px 5px;
  color: white;
  background-color: #1f1f1f;
  cursor: pointer;
}
.prev:focus,
.next:focus {
  outline: none;
}
.prev:hover,
.next:hover {
  background: powderblue;
}
.next {
  display: block;
  right: 5px;
  top: 150px;
}
.prev {
  display: block;
  left: 5px;
  top: 150px;
}
.before,
.after {
  position: absolute;
  display: block;
  height: 165px;
  width: 200px;
  padding-top: 100px;
  background: darkgray;
  border-radius: 2px;
  font-size: 40px;
  box-shadow: 0px 15px 10px -10px lightgray;
  margin-top: 20px;
}

.demo {
 
  border-collapse:collapse;
  padding:5px;
}
.demo th {
  border:1px solid #C0C0C0;
  padding:5px;
  background:#F0F0F0;
}
.demo td {
  border:1px solid #C0C0C0;
  padding:5px;
}

.hover-text {
  position: relative;
  display: inline-block;
  font-size: 3em;
  font-weight: 800;
  color: royalblue;
  overflow: hidden;
  background: linear-gradient(to right, midnightblue, midnightblue 50%, royalblue 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  transition: background-position 275ms ease;
  text-decoration: none; 
}
  .hover-text:hover {
    background-position: 0 100%;
  }
